import React from 'react';
import clsx from 'clsx';
import { Menu as MenuIcon } from 'react-feather';
import { Themes } from '../../../constants';
import Account from './Account';
import { ITopBarProps } from '../../../interfaces/navItemPropsInterface';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, Box, IconButton, Toolbar, SvgIcon, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer, // + 100,
      ...(theme.name === Themes.Light
        ? {
          backgroundColor: theme.palette.primary.main,
        }
        : {}),
      ...(theme.name === Themes.Dark
        ? {
          backgroundColor: theme.palette.background.default,
        }
        : {}),
    },
    toolbar: {
      minHeight: 64,
      color: theme.palette.text.primary,
    },
    menuButton: {
      color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.contrastText,
    },
  })
);

const TopBar: React.FC<ITopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true,
  });

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <IconButton id='divMenu' className={classes.menuButton} color="inherit" onClick={onMobileNavOpen} size="large">
            <SvgIcon>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box ml={2} flexGrow={1} display="flex" alignItems="center" justifyContent="center">
          {
            isMobile && <Typography id="lblCurrentBreadcrumb" variant="subtitle2"></Typography>
          }
        </Box>
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
