import React, { useState, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  avatar: {
    height: 56,
    width: 56,
    fontSize: 16,
  },
}));

interface IPageLayoutProps {
  children: ReactNode;
}

export const PageLayout: React.FC<IPageLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // setDashboardStatisticsHandle((record) => {
    //   enqueueSnackbar(record.data, { variant: 'success' });
    // });
  }, []);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.any,
};

export default PageLayout;
