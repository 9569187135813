import React, { createContext, useState, useEffect } from 'react';
import { storeSettings } from '../utils/settings';
import ThemeSettingsEntity from '../entities/common/ThemeSettingsEntity';
import { Themes } from '../constants';
import _ from 'lodash';

const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const defaultSettings: ThemeSettingsEntity = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: prefersDarkMode ? Themes.Dark : Themes.Light,
} as ThemeSettingsEntity;

interface SettingsContextProps {
  currentSettings: ThemeSettingsEntity;
  setCurrentSettings(currentSettings: ThemeSettingsEntity): void;
  handleSaveSettings(currentSettings: ThemeSettingsEntity): void;
}

const SettingsContext = createContext<SettingsContextProps>({
  currentSettings: defaultSettings,
  setCurrentSettings: () => {},
  handleSaveSettings: () => {},
});

interface ISettingsProviderProps {
  settings?: ThemeSettingsEntity;
  setCurrentSettings(settings: ThemeSettingsEntity): void;
  children: JSX.Element;
}

export const SettingsProvider: React.FC<ISettingsProviderProps> = ({ children }) => {
  const [currentSettings, setCurrentSettings] = useState<ThemeSettingsEntity>(defaultSettings);

  const handleSaveSettings = (updatedSettings = {}): void => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        currentSettings,
        setCurrentSettings,
        handleSaveSettings,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
