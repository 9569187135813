import { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import getInitials from '../../../utils/getInitials';
import { getUser } from '../../../utils/auth';
import { fetchAPI } from '../../../utils/fetchAPI';
import UserObjectResponseEntity from '../../../entities/response/UserObjectResponseEntity';
import { setDisconnectHandle } from '../../../utils/websocket';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Avatar, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 30,
    width: 30,
    fontSize: '14px',
    color: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.contrastText,
  },
  popover: {
    width: 200,
  },
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const user = getUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      localStorage.clear();

      await fetchAPI<UserObjectResponseEntity>('GET', 'auth/logout').then(() => {
        setDisconnectHandle(() => {
          console.log('disconnect');
        });
      });

      var oAuthUrl = (window as any).OAUTH_SIGNOUT_URL;
      if (oAuthUrl && oAuthUrl !== '') {
        return window.location.assign(oAuthUrl);
      } else history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout ' + error, {
        variant: 'error',
      });
    }
  };

  const getName = () => {
    return user && user.displayName ? user.displayName : '';
  };

  return (
    <>
      <IconButton onClick={handleOpen} ref={ref} size="large">
        <Avatar className={classes.avatar}>{getInitials(getName())}</Avatar>
      </IconButton>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem disabled>{getName()}</MenuItem>
        <MenuItem component={RouterLink} to="/app/myProfile">
          My Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
