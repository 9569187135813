/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { softShadows, strongShadows } from './shadows';
import { Themes } from '../constants';
import ThemeSettingsEntity from '../entities/common/ThemeSettingsEntity';
import { Theme, ThemeOptions } from '@mui/material/styles';
import { responsiveFontSizes, createTheme as createMuiTheme } from '@mui/material/styles';
import { grey, common } from '@mui/material/colors';
import { deepmerge } from '@mui/utils';

declare module '@mui/material/styles/createPalette' {
  //extend
  interface TypeBackground {
    dark: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    name: string;
  }

  interface DeprecatedThemeOptions {
    name: string;
  }
}

const baseConfig = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::placeholder": {
            fontSize: '0.8rem'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-formControl": {
            fontSize: '0.8rem',
            paddingTop: 3
          }
        }
      }
    }
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(5px)'
      }
    },
  },
} as ThemeOptions;

const lightThemeConfigs = {
  name: Themes.Light,
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: grey[600],
        },
      },
    },
  },
  palette: {
    mode: 'light',
    action: {
      active: grey[600],
    },
    background: {
      default: common.white,
      dark: '#f3f4f6',
      paper: common.white,
    },
    primary: {
      light: '#5e92f3',
      main: '#162041',//#162041
      dark: '#2d3b4d',
      contrastText: common.white,
    },
    secondary: {
      light: '#ff7961',
      main: '#162041',
      dark: '#162041',
      contrastText: common.white,
    },
  },
  shadows: softShadows,
};

const darkThemeConfigs = {
  name: Themes.Dark,
  palette: {
    mode: 'dark',
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#162041',
      dark: '#212332',
      paper: '#2a2d3e',
    },
    primary: {
      light: '#c3fdff',
      main: '#9c9ea2',
      dark: '#00A693',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc1e3',
      main: '#00A693',
      dark: '#005eb2',
    },
    text: {
      primary: '#e6e5e8',
      secondary: '#adb0bb',
    },
  },
  shadows: strongShadows,
};

export function createCustomTheme(settings: ThemeSettingsEntity): Theme {
  let themeConfig = darkThemeConfigs;//settings.theme === Themes.Light ? lightThemeConfigs : darkThemeConfigs;

  // if (!themeConfig) {
  //   console.warn(new Error(`The theme ${settings.theme} is not valid`));
  //   themeConfig = lightThemeConfigs;
  // }

  let theme = createMuiTheme(deepmerge(baseConfig, themeConfig));
  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
