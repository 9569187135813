import React from 'react';
import { Redirect } from 'react-router-dom';
import { checkLogin } from '../utils/auth';

export interface AuthGuardPropsType {
  children?: React.ReactNode;
}

declare global {
  interface Window {
    MyNamespace: any;
  }
}

const AuthGuard = ({ children }: AuthGuardPropsType) => {
  if (!checkLogin()) {
    var oAuthUrl = (window as any).OAUTH_URL;
    if (oAuthUrl && oAuthUrl !== '') {
      return window.location.assign(oAuthUrl);
    }
    return <Redirect to="/login" />;
  }

  return children;
};

export default AuthGuard;
