import React from 'react';
import { Redirect } from 'react-router-dom';
import { checkLogin } from '../utils/auth';

// export interface GuestGuardPropsType {
//   children?: React.ReactNode;
// }

// declare global {
//   interface Window { MyNamespace: any; }
// }

// const GuestGuard = ({ children }: GuestGuardPropsType) => {
//   if (checkLogin()) {
//     return <Redirect to="/" />;
//   }

//   return children;
// }

// export default GuestGuard;

export interface GuestGuardPropsType {
  children: JSX.Element;
}

const GuestGuard: React.FC<GuestGuardPropsType> = ({ children }: GuestGuardPropsType) => {
  if (checkLogin()) {
    return <Redirect to="/" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
