import { getToken } from './auth';

//RESTful API fetch
const getApiUrl = (path: string) => {
  return `/api${path.startsWith('/') ? '' : '/'}${path}`;
};

const getApiConfiguration = (method: string, jsonObj: any) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 15000);

  var opts: RequestInit = {
    method: method,
    signal: controller.signal
  };

  var requestHeaders = [];

  if (method.toUpperCase() !== 'GET')
    requestHeaders.push({ key: 'Content-Type', val: 'application/json' });

  let accessToken = getToken();
  if (accessToken)
    requestHeaders.push({ key: 'Authorization', val: `Bearer ${accessToken}` });

  opts.headers = requestHeaders.reduce(function (map: any, obj) {
    map[`${obj.key}`] = obj.val;
    return map;
  }, {});

  if (jsonObj) opts.body = JSON.stringify(jsonObj);

  return opts;
};

export const fetchAPI = async <TResponse>(method: string, url: string, jsonObj?: any, responseBlob?: boolean): Promise<TResponse> => {
  return new Promise(async function (resolve, reject) {
    try {
      //send request
      let apiUrl = getApiUrl(url);
      const resp = await fetch(apiUrl, getApiConfiguration(method, jsonObj));

      var respJson = null;
      if (Boolean(responseBlob))
        respJson = await resp.blob();
      else
        respJson = await resp.json();

      if (resp.status === 200) {
        //success respones
        resolve(respJson as TResponse);
      } else if (resp.status === 500) {
        //error respones
        reject(new Error(respJson.error || resp.statusText));
      } else if (resp.status === 401) {
        //if no login
        localStorage.clear();
        document.location.href = '/';
      } else {
        //failed respones cdx, return error message
        reject(new Error(respJson.error || resp.statusText));
      }
    } catch (err) {
      reject(new Error('Unable to connect to server, please check your network connection'));
    }
  });
};
