import React from 'react';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { INavBarButtonProps } from '../interfaces/navItemPropsInterface';

const NavBarLinks: React.FC<INavBarButtonProps> = ({
  className,
  activeClassName,
  exact,
  style,
  to,
  Icon,
  iconClassName,
  linkCaptionClassName,
  linkCaption,
  InfoIcon,
  infoClassName,
  ...rest
}) => (
  <>
    <Button component={NavLink} activeClassName={activeClassName} className={className} exact={exact} style={style} to={to}>
      {Icon && <Icon className={iconClassName} size="20" />}
      <span className={linkCaptionClassName}>{linkCaption}</span>
      {InfoIcon && <InfoIcon className={infoClassName} />}
    </Button>
  </>
);

export default NavBarLinks;
