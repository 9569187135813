/* eslint-disable import/prefer-default-export */
export enum Themes {
  Dark = 'Dark',
  Light = 'Light',
}

export const AppSetting = {
  ApplicationName: 'SYSCAMPO',
  ApplicationShortName: 'SYSCAMPO',
  CompanyName: 'CEC',
  LongDatePattern: 'YYYY-MM-DD HH:mm:ss',
  ShortDatePattern: 'YYYY-MM-DD'
};

export const FormMode = {
  Add: 1,
  Edit: 2,
};

export const AccessTag = {
  DASHBOARD: 'Dashboard',
  USERMANAGEMENT: 'UserManagement',
  SHIPMENTTRACKING: 'ShipmentTracking',
  STOCKBALANCE: 'StockBalance',
  PICKINGLIST: 'PickingList',
  PERMISSIONMANAGEMENT: 'PermissionManagement',
  DASHBOARDSTATISTICS: 'DashboardStatistics',
  REPORT: 'Report',
  SETUP: 'Setup'
};

export const AccessRight = {
  VIEWACCESS: 'viewAccess',
  CREATEACCESS: 'createAccess',
  MODIFYACCESS: 'modifyAccess',
  DELETEACCESS: 'deleteAccess',
  EXPORTACCESS: 'exportAccess',
};

export enum ReportTypeEnum {
  VehicleMaintenanceSummaryReport = 1,
  VehicleMaintenanceByVendor = 2,
  FuelConsumptionReport = 3
}

export const StockBalanceTransactionType = {
  Inbound: 'INBOUND',
  Outbound: 'OUTBOUND',
  Sample: 'SAMPLE',
  Adjustment: 'ADJUSTMENT'
};

export const InvoiceStatus = {
  Paid: 'PAID',
  Cancelled: 'CANCELLED',
  Voided: 'VOIDED'
};

export const PickingStatus = {
  Unpick: 'UNPICK',
  Picked: 'PICKED',
  Picking: 'PICKING',
};

export const StockType = {
  Physical: 'PHYSICAL',
  PostSales: 'POSTSALES'
};