import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { SettingsProvider } from './context/SettingsContext';
import { UserProvider } from './context/UserContext';
import { restoreSettings } from './utils/settings';
//import reportWebVitals from './reportWebVitals';

const settings = restoreSettings();

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider setCurrentSettings={settings}>
      <UserProvider>
        <App />
      </UserProvider>
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
