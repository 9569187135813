import { createContext, useContext, useState } from 'react';
import UserPermissionEntity from '../entities/common/UserPermissionEntity';
import UserRoleEntity from '../entities/common/UserRoleEntity';

export type UserContextType = {
  id: string;
  setId: (id: string) => void;

  displayName: string;
  setDisplayName: (displayName: string) => void;

  role: UserRoleEntity | null;
  setRole: (role: UserRoleEntity) => void;

  permission: UserPermissionEntity[] | [];
  setPermission: (permission: UserPermissionEntity[]) => void;

  ip: string;
  setIp: (ip: string) => void;
};

export const UserContext = createContext<UserContextType>({
  id: '',
  setId: () => {},
  displayName: '',
  setDisplayName: () => {},
  role: null,
  setRole: () => {},
  permission: [],
  setPermission: () => {},
  ip: '',
  setIp: () => {},
});

export const useUser = () => useContext(UserContext);

interface IUserProviderProps {
  children: JSX.Element;
}

export const UserProvider: React.FC<IUserProviderProps> = ({ children }) => {
  const [id, setId] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [role, setRole] = useState<UserRoleEntity | null>(null);
  const [permission, setPermission] = useState<UserPermissionEntity[]>([]);
  const [ip, setIp] = useState('');

  return (
    <UserContext.Provider
      value={{
        id,
        displayName,
        role,
        permission,
        ip,
        setId,
        setDisplayName,
        setRole,
        setPermission,
        setIp,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
