import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { INavItemProps } from '../../../interfaces/navItemPropsInterface';
import NavBarLinks from '../../../components/navBarLinks';
import { Button, Collapse, ListItem } from '@mui/material';

const LocalExpandMoreIcon: any = ExpandMoreIcon;
const LocalExpandLessIcon: any = ExpandLessIcon;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'block',
      paddingTop: 0,
      paddingBottom: 0,
    },
    itemLeaf: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
    },
    buttonLeaf: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightRegular,
      '&.depth-0': {
        '& $title': {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    title: {
      marginRight: 'auto',
    },
    active: {
      color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main,
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& $icon': {
        color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main,
      },
    },
    info: {},
  })
);

const NavItem: React.FC<INavItemProps> = ({ title, href, depth, children, icon: Icon, className, open: openProp, info: Info, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <LocalExpandMoreIcon size="small" color="inherit" /> : <LocalExpandLessIcon size="small" color="inherit" />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <NavBarLinks
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        exact={true}
        style={style}
        to={href}
        Icon={Icon}
        iconClassName={classes.icon}
        linkCaption={title}
        linkCaptionClassName={classes.title}
        InfoIcon={Info}
        infoClassName={classes.info}></NavBarLinks>
    </ListItem>
  );
};

export default NavItem;
