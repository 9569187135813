/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import NavItem from './NavItem';
import { AccessTag } from '../../../constants/index';
import { getUserPermission } from '../../../utils/auth';
import { INavItemProps } from '../../../interfaces/navItemPropsInterface';
import {
  List as ReportIcon,
  Package as PackageIcon,
  Settings as SysSettingIcon,
  PieChart as DashboardIcon,
  Users as UsersIcon,
  Command as CommandIcon,
  Coffee as CoffeeIcon,
  FileText as InvoicesIcon,
} from 'react-feather';

import { List, ListSubheader, Drawer, Divider, Box, Typography } from '@mui/material';

// import Logo from 'src/components/Logo';

const useStyles = makeStyles(() =>
  createStyles({
    mobileDrawer: {
      width: 256,
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)',
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
    },
  })
);

function navConfigItem() {
  return [
    {
      subheader: '',
      items: [
        {
          title: 'Dashboard',
          icon: DashboardIcon,
          href: '/app',
          tag: AccessTag.DASHBOARD,
        },
        {
          title: 'Shipment Tracking',
          icon: PackageIcon,
          href: '/app/shipmentTracking',
          tag: AccessTag.SHIPMENTTRACKING,
        },
        {
          title: 'Stock Balance',
          icon: CoffeeIcon,
          href: '/app/stockBalance',
          tag: AccessTag.STOCKBALANCE,
        },
        {
          title: 'Picking List',
          icon: InvoicesIcon,
          href: '/app/pickingList',
          tag: AccessTag.PICKINGLIST,
        },
        {
          title: 'User Management',
          icon: UsersIcon,
          href: '/app/users',
          tag: AccessTag.USERMANAGEMENT,
        },
        {
          title: 'Report',
          icon: ReportIcon,
          href: '/app/report',
          tag: AccessTag.REPORT,
        },
        {
          title: 'Permission',
          icon: CommandIcon,
          href: '/app/permission',
          tag: AccessTag.PERMISSIONMANAGEMENT,
        },
        {
          title: 'Setup',
          icon: SysSettingIcon,
          href: '/app/setup',
          tag: AccessTag.SETUP,
        },
      ],
    },
  ];
}

type NavTopPros = {
  items: INavItemProps[];
  pathname: string;
  depth: number;
};

const renderNavItems: React.FC<NavTopPros> = ({ items, pathname, depth, ...rest }) => {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes(acc, item, pathname, depth), [])}</List>;
};

function reduceChildRoutes(acc: any, item: INavItemProps, pathname: string, depth: number) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title} href={''}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
  }

  return acc;
}

interface INavBarProps {
  openMobile?: boolean | undefined;
  onMobileClose?: () => void;
}

const NavBar: React.FC<INavBarProps> = ({ openMobile, onMobileClose }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  let userPermissions = getUserPermission();
  let permission = userPermissions && userPermissions.length > 0 ? userPermissions.filter((m) => m.viewAccess).map((m) => m.accessType) : null;
  var navFilterConfig = navConfigItem();

  if (permission) {    
    navFilterConfig[0].items = navFilterConfig[0].items.filter(
      (m) => m.tag === AccessTag.DASHBOARD || (permission && permission.includes(m.tag))
    );
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {navFilterConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }>
              {renderNavItems({
                items: config.items as INavItemProps[],
                pathname: location.pathname,
                depth: 0,
              })}
            </List>
          ))}
        </Box>
        {process.env.REACT_APP_VERSION && (
          <Box>
            <Divider />
            <Box p={2}>
              <Typography variant="caption" color="textSecondary">
                Build {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </Box>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        ModalProps={{
          keepMounted: true,
        }}
        variant="temporary">
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
