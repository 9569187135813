import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import AuthGuard from './components/authGuard';
import GuestGuard from './components/guestGuard';
import MainLayout from './layouts/MainLayout';
import { v4 as uuid } from 'uuid';
import LoadingScreen from './components/loadingScreen';

type RouteProps = {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path?: string;
  exact?: boolean;
  layout?: React.ReactNode | null;
  guard?: React.ReactNode;
  routes?: RouteProps[];
};

function getRoutesConfig(): RouteProps[] {
  return [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/app" />,
    },
    {
      exact: true,
      path: '/404',
      component: lazy(() => import('./views/common/errors/error404View')),
    },
    {
      exact: true,
      guard: GuestGuard as unknown as React.ReactNode,
      path: '/oauthlogin',
      component: lazy(() => import('./views/auth/oAuthLoginView')),
    },
    {
      exact: true,
      guard: GuestGuard as unknown as React.ReactNode,
      path: '/oauthloginfailed',
      component: lazy(() => import('./views/auth/oAuthLoginFailedView')),
    },
    {
      exact: true,
      guard: GuestGuard as unknown as React.ReactNode,
      path: '/login',
      component: lazy(() => import('./views/auth/loginView')),
    },
    {
      exact: true,
      guard: AuthGuard as unknown as React.ReactNode,
      path: '/landing',
      component: lazy(() => import('./views/auth/landing')),
    },
    {
      path: '/app',
      guard: AuthGuard as unknown as React.ReactNode,
      layout: MainLayout as unknown as React.ReactNode,
      routes: [
        {
          exact: true,
          path: '/app',
          component: lazy(() => import('./views/dashboard')),
        },
        {
          exact: true,
          path: '/app/users',
          component: lazy(() => import('./views/userManagement')),
        },
        {
          exact: true,
          path: '/app/myProfile',
          component: lazy(() => import('./views/userManagement/myProfile')),
        },
        {
          exact: true,
          path: '/app/permission',
          component: lazy(() => import('./views/permission')),
        },
        {
          exact: true,
          path: '/app/setup',
          component: lazy(() => import('./views/setup')),
        },
        {
          exact: true,
          path: '/app/shipmentTracking',
          component: lazy(() => import('./views/shipmentTracking')),
        },
        {
          exact: true,
          path: '/app/stockBalance',
          component: lazy(() => import('./views/stockBalance')),
        }, 
        {
          exact: true,
          path: '/app/pickingList',
          component: lazy(() => import('./views/pickingList')),
        },
        {
          exact: true,
          path: '/app/report',
          component: lazy(() => import('./views/report')),
        },
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
  ];
}

const renderRoutes = (routes: RouteProps[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = (route.guard as React.ElementType) || Fragment;
          const Layout = (route.layout as React.ElementType) || Fragment;
          const Component = route.component as React.ElementType;

          return (
            <Route
              key={uuid()}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <div></div>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function RoutesProvider() {
  var routesConfig = getRoutesConfig();
  return renderRoutes(routesConfig);
}

export default RoutesProvider;
