import { io, Socket } from 'socket.io-client';
import { ServerToClientEvents, ClientToServerEvents } from '../interfaces/websocketInterface';
import { getToken } from './auth';
import moment from 'moment';

let clientSocket: Socket<ServerToClientEvents, ClientToServerEvents>;

function connect() {
  let token = getToken();
  clientSocket = require('socket.io-client')({
    query: { token, date: moment().startOf('day').utc().format() }
  });

  clientSocket.on('connect', () => {
    console.log('websocket connected');
  });
}

function setDisconnectHandle(callBack: (data: any) => void) {
  if (clientSocket) {
    clientSocket.on('disconnect', (reason: any) => {
      console.log('disconnect:' + clientSocket.id);
      callBack(reason);
    });
  }
}

function setReconnectHandle(callBack: (data: any) => void) {
  clientSocket.on('disconnect', () => {
    connect();
  });
}


function setPingHandle(callBack: (data: any) => void) {
  if (clientSocket) {
    clientSocket.on('ping', (ping) => {
      callBack(ping);
    });
  }
}

function setPongHandle() {
  if (clientSocket) {
    clientSocket.emit('pong', clientSocket.id, getToken() ?? '');
  }
}

function getSocketId() {
  if (clientSocket) return clientSocket.id;
  return '';
}

export {
  connect,
  setDisconnectHandle,
  setReconnectHandle,
  setPingHandle,
  setPongHandle,
  getSocketId,
};
