import { create } from 'jss';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { createStyles, makeStyles, StylesProvider, jssPreset } from '@mui/styles';
import { createCustomTheme } from './theme/index';
import useSettings from './hooks/useSettings';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import RoutesProvider from './routes';

const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const history = createBrowserHistory();
const jss = create({
  ...jssPreset(),
  insertionPoint: 'jss-insertion-point',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

function App() {
  useStyles();
  const { currentSettings } = useSettings();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createCustomTheme(currentSettings)}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider maxSnack={5}>
              <Router history={history}>
                {/* <ScrollReset /> */}
                <RoutesProvider />
              </Router>
            </SnackbarProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
