import jwtDecode, { JwtPayload } from 'jwt-decode';
import UserObjectEntity from '../entities/common/UserObjectEntity';
import UserPermissionEntity from '../entities/common/UserPermissionEntity';
import UserRoleEntity from '../entities/common/UserRoleEntity';

type customJwtPayload = JwtPayload & {
  id: string;
  userIntegerId: number;
  isSystemAdministrator: boolean;
  permission: UserPermissionEntity[];
};

export const setToken = (tokenStr: string) => {
  localStorage.setItem('token', tokenStr);
};

export const setTokenCookie = (tokenStr: string) => {
  document.cookie = `token=${tokenStr}`;
};

export const getToken = (): string | null => {
  let token = localStorage.getItem('token');
  return token;
};

export const setUser = (userObj: UserObjectEntity, userRole: UserRoleEntity): void => {
  const userStored = {
    userId: userObj.userId,
    displayName: userObj.displayName,
    id: userObj.id,
    roleCode: userRole?.roleCode || ""
  };

  localStorage.setItem('user', JSON.stringify(userStored));
};

export const getUser = (): UserObjectEntity | null => {
  try {
    const userObj = JSON.parse(localStorage.getItem('user') || '{}') as UserObjectEntity;
    if (userObj && userObj.id) {
      return userObj;
    } else {
      throw new Error('error');
    }
  } catch (err) {
    return null;
  }
};

export const getUserIntId = (): number => {
  try {
    var userid = jwtDecode<customJwtPayload>(getToken() || '').userIntegerId;
    return userid;
  } catch (err) {
    return -999;
  }
};

export const getIsAdministrator = (): boolean => {
  try {
    var isSystemAdministrator = jwtDecode<customJwtPayload>(getToken() || '').isSystemAdministrator;
    if (isSystemAdministrator) return true;

    return false;
  } catch (err) {
    return false;
  }
};

export const getUserPermission = (): UserPermissionEntity[] | null => {
  try {
    const permissions = jwtDecode<customJwtPayload>(getToken() || '');
    if (permissions && permissions.permission) {
      return permissions.permission;
    } else throw new Error('error');
  } catch (err) {
    return null;
  }
};

export const checkLogin = (): boolean => {
  try {
    var token = Boolean(getToken());
    var payload = jwtDecode<customJwtPayload>(getToken() || '');
    var currentUser = getUser();
    return token && payload.id === currentUser?.id;
  } catch (err) {
    return false;
  }
};

export const checkPermission = (accessType: string, action: string): boolean => {
  try {
    const permissions = jwtDecode<customJwtPayload>(getToken() || '');
    
    if (permissions && permissions.permission) {
      return Boolean(permissions.permission.find((p) => p.accessType === accessType && p[action] === true));
    }
    return false;
  } catch (err) {
    return false;
  }
};
